import { styled } from '@mui/material/styles';

export const ContentStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '380px',
    padding: '48px 22px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    background: theme.palette.background.default,
    boxShadow:
      theme.palette.mode === 'light'
        ? `0 4px 6px rgba(0, 0, 0, 0.1), 
    0 1px 3px rgba(0, 0, 0, 0.08)`
        : 'none',
    border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
    borderColor: theme.palette.secondary.main,
    borderRadius: '10px',
  },
  maxWidth: '500px',
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  boxShadow:
    theme.palette.mode === 'light'
      ? `0 4px 6px rgba(0, 0, 0, 0.1), 
    0 1px 3px rgba(0, 0, 0, 0.08)`
      : 'none',
  background: theme.palette.background.default,
  border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
  borderColor: theme.palette.secondary.main,
  borderRadius: '10px',
}));
