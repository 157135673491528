import { useState } from 'react';

export const useInstance = () => {
  const [instance, setInstance] = useState<string | null>(() => {
    const storedInstance = localStorage.getItem('backend_instance');
    if (!storedInstance) {
      return null;
    }
    return JSON.parse(storedInstance).api_base_url;
  });

  const getInstance = () => {
    return instance;
  };

  const setInstanceValue = (newInstance: string) => {
    setInstance(newInstance);
    localStorage.setItem('backend_instance', JSON.stringify(newInstance));
  };

  return { instance: getInstance(), setInstance: setInstanceValue };
};
