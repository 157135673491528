import { IDay } from '@common/interfaces/IPreferences';

export const CHANNELS = [
  {
    key: 'veeva',
    translationKey: 'VEEVA_CRM',
    disabled: true,
  },
  {
    key: 'ms_team',
    translationKey: 'MICROSOFT_TEAMS',
    disabled: false,
  },
  {
    key: 'ms_outlook',
    translationKey: 'EMAIL_NOTIFICATION',
    disabled: false,
  },
];

export const DAYS_OF_WEEK: IDay[] = [
  { label: 'Mon', ariaLabel: 'MONDAY', selected: false },
  { label: 'Tue', ariaLabel: 'TUESDAY', selected: false },
  { label: 'Wed', ariaLabel: 'WEDNESDAY', selected: false },
  { label: 'Thu', ariaLabel: 'THURSDAY', selected: false },
  { label: 'Fri', ariaLabel: 'FRIDAY', selected: false },
  { label: 'Sat', ariaLabel: 'SATURDAY', selected: false },
  { label: 'Sun', ariaLabel: 'SUNDAY', selected: false },
];

export const TIME_ZONES = [
  { value: 'GMT-12:00', label: '(GMT-12:00) International Date Line West' },
  { value: 'GMT-11:00', label: '(GMT-11:00) Midway Island, Samoa' },
  { value: 'GMT-10:00', label: '(GMT-10:00) Hawaii' },
  { value: 'GMT-09:00', label: '(GMT-09:00) Alaska' },
  { value: 'GMT-08:00', label: '(GMT-08:00) Pacific Time (US & Canada)' },
  { value: 'GMT-07:00', label: '(GMT-07:00) Mountain Time (US & Canada)' },
  { value: 'GMT-06:00', label: '(GMT-06:00) Central Time (US & Canada), Mexico City' },
  { value: 'GMT-05:00', label: '(GMT-05:00) Eastern Time (US & Canada), Bogota, Lima' },
  { value: 'GMT-04:00', label: '(GMT-04:00) Atlantic Time (Canada), Caracas, La Paz' },
  { value: 'GMT-03:30', label: '(GMT-03:30) Newfoundland' },
  { value: 'GMT-03:00', label: '(GMT-03:00) Brazil, Buenos Aires, Georgetown' },
  { value: 'GMT-02:00', label: '(GMT-02:00) Mid-Atlantic' },
  { value: 'GMT-01:00', label: '(GMT-01:00) Azores, Cape Verde Islands' },
  { value: 'GMT+00:00', label: '(GMT+00:00) Western Europe Time, London, Lisbon, Casablanca' },
  { value: 'GMT+01:00', label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris' },
  { value: 'GMT+02:00', label: '(GMT+02:00) Kaliningrad, South Africa' },
  { value: 'GMT+03:00', label: '(GMT+03:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
  { value: 'GMT+03:30', label: '(GMT+03:30) Tehran' },
  { value: 'GMT+04:00', label: '(GMT+04:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
  { value: 'GMT+04:30', label: '(GMT+04:30) Kabul' },
  { value: 'GMT+05:00', label: '(GMT+05:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
  { value: 'GMT+05:30', label: '(GMT+05:30) Bombay, Calcutta, Madras, New Delhi' },
  { value: 'GMT+05:45', label: '(GMT+05:45) Kathmandu' },
  { value: 'GMT+06:00', label: '(GMT+06:00) Almaty, Dhaka, Colombo' },
  { value: 'GMT+07:00', label: '(GMT+07:00) Bangkok, Hanoi, Jakarta' },
  { value: 'GMT+08:00', label: '(GMT+08:00) Beijing, Perth, Singapore, Hong Kong' },
  { value: 'GMT+09:00', label: '(GMT+09:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
  { value: 'GMT+09:30', label: '(GMT+09:30) Adelaide, Darwin' },
  { value: 'GMT+10:00', label: '(GMT+10:00) Eastern Australia, Guam, Vladivostok' },
  { value: 'GMT+11:00', label: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia' },
  { value: 'GMT+12:00', label: '(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka' },
];

export const THEME_OPTIONS = [
  {
    translationKey: 'GLOBAL_LIGHT',
    value: 'light',
  },
  {
    translationKey: 'GLOBAL_DARK',
    value: 'dark',
  },
  {
    translationKey: 'GLOBAL_SYSTEM',
    value: 'system',
  },
];
