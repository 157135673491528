import { Box, Theme } from '@mui/material';
import styled from '@emotion/styled';

const easing = [0.6, -0.05, 0.01, 0.99];
export const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

export const fadeInUpSignUp = {
  initial: {
    y: 40,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

export const RootStyle = styled('div')<{ theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
}));

export const HeadingStyle = styled(Box)({
  textAlign: 'center',
});

export const ContentStyle = styled(Box)<{ theme?: Theme }>(({ theme }) => ({
  maxWidth: 480,
  padding: theme?.spacing(3) || 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  boxShadow:
    theme.palette.mode === 'light'
      ? `0 4px 6px rgba(0, 0, 0, 0.1), 
    0 1px 3px rgba(0, 0, 0, 0.08)`
      : 'none',
  backgroundColor: theme.palette.background.default,
  border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
  borderColor: theme.palette.secondary.main,
  borderRadius: '10px',
}));
