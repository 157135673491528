import { AxiosResponse } from 'axios';
import { GlobalVariables, config } from '../config/configurations';
import { app } from '@microsoft/teams-js';
import { cleanLocalStorage, cleanLocalStorageExceptInit } from '../helpers/localStorage';
import { instance } from './baseApi';

const globalVar = GlobalVariables.getInstance();

export function forceLogout(): Promise<AxiosResponse> {
  instance
    .post(`/${config.REACT_APP_API_VERSION}/authentication/oauth2/logout`)
    .then((_response) => {
      cleanLocalStorageExceptInit();
    })
    .catch((_error) => {
      cleanLocalStorage();
    })
    .finally(() => {
      if (
        window.location.pathname === '/login' ||
        window.location.pathname === '/index.html#/login'
      ) {
        return;
      }
      if (app.isInitialized()) {
        window.location.href = '/config';
      } else if (globalVar.integrationMode !== 'addon') {
        window.location.href = '/login';
      } else {
        window.location.href = '/index.html#/login';
      }
    });
  return Promise.resolve({} as AxiosResponse);
}

export function getAuthUrlClientCredentials(
  provider: string,
  redirectUrl: string,
): Promise<AxiosResponse> {
  return instance.post(`/${config.REACT_APP_API_VERSION}/authentication/oauth2/authorize`, {
    provider: provider,
    redirect_uri: redirectUrl,
    grant_type: 'client_credentials',
  });
}

export function getProviderURl(provider: string): Promise<AxiosResponse> {
  return instance.get(
    `/${config.REACT_APP_API_VERSION}/authentication/oauth2/${provider}/authorize`,
  );
}

export function getGrantUrl(
  provider: string,
  token: string,
  tokenType: string,
  mode?: string,
): Promise<AxiosResponse> {
  return instance.post(
    `/${
      config.REACT_APP_API_VERSION
    }/authentication/oauth2/grant?mode=${globalVar.getIntegrationMode()}`,
    {
      grant_type: 'sso_login',
      provider: provider,
      token: token,
      token_type: tokenType,
      mode: mode,
    },
  );
}

export function getMultiAccount(state: string): Promise<AxiosResponse> {
  return instance.get(`/${config.REACT_APP_API_VERSION}/authentication/oauth2/my/accounts`, {
    params: {
      state: state,
    },
  });
}

export function selectAccount(state: string, organizationId: string): Promise<AxiosResponse> {
  return instance.post(`/${config.REACT_APP_API_VERSION}/authentication/oauth2/my/accounts`, {
    state: state,
    organization: organizationId,
  });
}
