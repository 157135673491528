import frFR from '../locales/fr-FR.json';
import deDE from '../locales/de-DE.json';
import itIT from '../locales/it-IT.json';
import ruRU from '../locales/ru-RU.json';
import enUS from '../locales/en-US.json';
import enGB from '../locales/en-GB.json';

export const resources = {
  'fr-FR': { translation: frFR },
  'de-DE': { translation: deDE },
  'it-IT': { translation: itIT },
  'ru-RU': { translation: ruRU },
  'en-US': { translation: enUS },
  'en-GB': { translation: enGB },
};

export const LANGUAGES = [
  {
    name: 'English',
    code: 'en-US',
  },
  {
    name: 'French',
    code: 'fr-FR',
  },
  {
    name: 'German',
    code: 'de-DE',
  },
  {
    name: 'Italy',
    code: 'it-IT',
  },
  {
    name: 'Russian',
    code: 'ru-RU',
  },
];

export const FALLBACK_LANGUAGE = 'fr-FR';
