import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Link, Box, Divider } from '@mui/material';
import { t } from 'i18next';

import SocialAuth from '../../components/login/social';
import SignupForm from '../../components/login/signup';
import Logo from '../../components/login/logo';
import { motion } from 'framer-motion';
import { fadeInUpSignUp, RootStyle, ContentStyle, HeadingStyle } from './auth-styles';
import { LoginProps } from '../../interfaces/ILogin';

const Signup: React.FC<LoginProps> = (props: LoginProps) => {
  const allowUserPassword = false;

  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUpSignUp}>
            <Logo />
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              {t('GLOBAL_AUTH_CHOOSE_PROVIDER')}.
            </Typography>
          </HeadingStyle>
          <Box component={motion.div} {...fadeInUpSignUp}>
            <SocialAuth direction="column" {...props} />
          </Box>

          {allowUserPassword && (
            <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUpSignUp}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('GLOBAL_AUTH_OR')}
              </Typography>
            </Divider>
          )}
          {allowUserPassword && (
            <Box component={motion.div} {...fadeInUpSignUp}>
              <SignupForm />
            </Box>
          )}

          <Typography
            component={motion.p}
            {...fadeInUpSignUp}
            variant="body2"
            align="center"
            sx={{ color: 'text.secondary', mt: 2 }}
          >
            {`${t('GLOBAL_AUTH_IAGREE')}`}
            <Link
              variant="subtitle2"
              component={RouterLink}
              aria-labelledby="terms-and-conditions"
              to="https://usesecondbrain.io/general-terms-and-conditions/"
              rel="noopener noreferrer"
              target="_blank"
              sx={{ mx: 0.3 }}
            >
              {t('GLOBAL_AUTH_TERMS')}
            </Link>
            &
            <Link
              variant="subtitle2"
              component={RouterLink}
              aria-labelledby="privacy-policy"
              to="https://usesecondbrain.io/privacy-data-protection-statement/"
              rel="noopener noreferrer"
              target="_blank"
              sx={{ mx: 0.3 }}
            >
              {t('GLOBAL_AUTH_PRIVACY_POLICY')}
            </Link>
            .
          </Typography>

          <Typography
            component={motion.p}
            {...fadeInUpSignUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            {`${t('GLOBAL_AUTH_HAVEACCOUNT')}? `}
            <Link variant="subtitle2" component={RouterLink} to="/login">
              {t('GLOBAL_AUTH_LOGIN')}.
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Signup;
