import { Google as GoogleIcon, Microsoft as MicrosoftIcon } from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import {
  getAuthUrlClientCredentials,
  getGrantUrl,
  getProviderURl,
} from '../../apis/authentication';
import { GlobalVariables, config } from '../../config/configurations';
import { cleanMeetingFromLocalStorage } from '../../helpers/localStorage';
import { SocialLoginProps } from '../../interfaces/ILogin';
import MicrosoftLoginComponent from './login-popup';

const globalVar = GlobalVariables.getInstance();

const SocialAuth: React.FC<SocialLoginProps> = (props: SocialLoginProps) => {
  const [is_connected, setIsConnected] = React.useState(false);
  const [microsoftPopUp, setMicrosoftPopUp] = React.useState(false);
  const [microsoftPopUpUrls, setMicrosoftPopUpUrls] = React.useState({
    authorizeUrl: '',
  });
  const getProviderURlInAnyCase = async (provider: string) => {
    if (props.mode === 'addon') {
      const base_url = `${config.REACT_APP_URL_BACKEND_API}/${config.REACT_APP_API_VERSION}/authentication/oauth2`;
      props.callback(
        provider,
        `${base_url}/authorize?mode=${globalVar.getIntegrationMode()}`,
        `${base_url}/grant?mode=${globalVar.getIntegrationMode()}`,
        setIsConnected,
      );
    } else if (props.mode === 'office-add-in' && provider === 'microsoft') {
      // eslint-disable-next-line no-undef
      const userTokenEncoded = await OfficeRuntime.auth.getAccessToken();
      if (userTokenEncoded) {
        getGrantUrl('microsoft', userTokenEncoded, 'access_token', 'office-add-in')
          .then((_res: any) => {
            window.location.href = '/homepage/summary';
          })
          .catch((_error) => {
            // eslint-disable-next-line no-console
            console.error('impossible to transform the token, fallback to popup');
            redirectPopup();
          });
      }
      if (!userTokenEncoded) {
        redirectPopup();
      }
    } else {
      getProviderURl(provider).then((res) => {
        cleanMeetingFromLocalStorage();
        window.location.href = res.data.redirect;
      });
    }
  };

  const redirectPopup = () => {
    getAuthUrlClientCredentials('microsoft', `${window.location.origin}/redirect`).then(
      (res: any) => {
        const authorizeUrl = res.data.redirect;
        localStorage.setItem('provider', 'microsoft');
        setMicrosoftPopUpUrls({
          authorizeUrl: authorizeUrl,
        });
        window.addEventListener('message', (event) => {
          if (event.origin === window.location.origin) {
            if (event.data === 'popUpClose') {
              setMicrosoftPopUp(false);
              window.removeEventListener('message', () => {
                // eslint-disable-next-line no-console
                console.log('remove event listener');
              });
              window.location.href = '/homepage/summary';
            }
          }
        });
      },
    );
  };

  useEffect(() => {
    if (microsoftPopUpUrls.authorizeUrl !== '') {
      setMicrosoftPopUp(true);
    }
  }, [microsoftPopUpUrls]);

  useEffect(() => {
    if (is_connected) {
      window.location.href = '/index.html#';
    }
  }, [is_connected]);

  return (
    <>
      <Stack direction={props.direction} spacing={2}>
        {props.mode !== 'office-add-in' && (
          <>
            {props.direction === 'row' && (
              <IconButton
                onClick={() => {
                  getProviderURlInAnyCase('google');
                }}
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '5px',
                  padding: '0.5675rem',
                  flex: 1,
                  '&:hover': {
                    border: '2px solid #ccc',
                  },
                }}
              >
                <GoogleIcon />
              </IconButton>
            )}
            {props.direction === 'column' && (
              <Button
                fullWidth
                size="large"
                variant="outlined"
                onClick={() => {
                  getProviderURlInAnyCase('google');
                }}
                sx={{
                  border: '2px solid #ccc',
                  borderRadius: '5px',
                  padding: '0.5675rem',
                  flex: 1,
                  color: 'text.secondary',
                  '&:hover': {
                    border: '2px solid #ccc',
                  },
                }}
                startIcon={<GoogleIcon />}
              >
                Google
              </Button>
            )}
          </>
        )}
        <>
          {props.direction === 'row' && (
            <IconButton
              onClick={() => {
                getProviderURlInAnyCase('microsoft');
              }}
              sx={{
                border: '2px solid #ccc',
                borderRadius: '5px',
                padding: '0.5675rem',
                flex: 1,
                '&:hover': {
                  border: '2px solid #ccc',
                },
              }}
            >
              <MicrosoftIcon />
            </IconButton>
          )}
          {props.direction === 'column' && (
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => {
                getProviderURlInAnyCase('microsoft');
              }}
              sx={{
                border: '2px solid #ccc',
                borderRadius: '5px',
                padding: '0.5675rem',
                flex: 1,
                color: 'text.secondary',
                '&:hover': {
                  border: '2px solid #ccc',
                },
              }}
              startIcon={<MicrosoftIcon />}
            >
              Microsoft
            </Button>
          )}
        </>
      </Stack>
      {microsoftPopUp && <MicrosoftLoginComponent authorizeUrl={microsoftPopUpUrls.authorizeUrl} />}
    </>
  );
};

export default SocialAuth;
