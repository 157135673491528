import './App.css';
import { LoginProvider } from './common/pages/login/login-context';
import { ToastProvider } from './administration/common/components/toast/ToastPovider';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeWrapper from './common/theme';
import router from './routes';
import { store } from './store';

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <LoginProvider>
          <ThemeWrapper>
            <RouterProvider router={router} />
          </ThemeWrapper>
        </LoginProvider>
      </ToastProvider>
    </Provider>
  );
}

export default App;
