import { alpha, Theme } from '@mui/material/styles';

export default function Alert(theme: Theme) {
  const isDarkMode = theme.palette.mode === 'dark';

  return {
    MuiAlert: {
      variants: [
        {
          props: { severity: 'info-sbrain' },
          style: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: isDarkMode
              ? alpha(theme.palette.primary.main, 0.15)
              : alpha(theme.palette.primary.main, 0.07),
            color: isDarkMode ? theme.palette.primary.light : theme.palette.primary.main,
            fontSize: '1rem',
            '& .MuiAlert-icon': {
              color: isDarkMode ? theme.palette.primary.light : theme.palette.primary.main,
            },
          },
        },
      ],
    },
  };
}
