import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStoredTheme } from './helpers';
import { TypeMode } from '@common/interfaces/IPreferences';

const themeSlice = createSlice({
  name: 'themeSlice',
  initialState: {
    mode: getStoredTheme('theme'),
    displayMode: getStoredTheme('display_theme'),
  },
  reducers: {
    setTheme: (state, action: PayloadAction<{ mode: TypeMode }>) => {
      if (action.payload.mode === 'system') {
        const isSetToDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
        state.mode = isSetToDarkMode ? 'dark' : 'light';
        state.displayMode = 'system';
      } else {
        state.mode = action.payload.mode;
        state.displayMode = action.payload.mode;
      }
      localStorage.setItem('theme', state.mode);
      localStorage.setItem('display_theme', state.displayMode);
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
