import { Theme } from '@mui/material/styles';

export default function Avatar(theme: Theme) {
  const isDarkMode = theme.palette.mode === 'dark';

  return {
    MuiAvatar: {
      variants: [
        {
          props: { variant: 'priority-urgent' },
          style: {
            color: isDarkMode ? '#FF6B6B' : theme.palette.text.redNeutral,
            backgroundColor: isDarkMode
              ? 'rgba(255, 107, 107, 0.2)'
              : theme.palette.secondary.light,
            textTransform: 'capitalize',
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'priority-important' },
          style: {
            color: isDarkMode ? '#FFA500' : theme.palette.text.orangeNeutral,
            backgroundColor: isDarkMode
              ? 'rgba(255, 165, 0, 0.2)'
              : theme.palette.background.orange,
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'priority-moderate' },
          style: {
            color: isDarkMode ? '#4CAF50' : theme.palette.text.greenNeutral,
            backgroundColor: isDarkMode ? 'rgba(76, 175, 80, 0.2)' : theme.palette.background.green,
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      ],
    },
  };
}
