import { Theme } from '@mui/material/styles';

export default function Typography(theme: Theme) {
  const isDarkMode = theme.palette.mode === 'dark';

  return {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'text-lg' },
          style: {
            fontSize: '18px',
            lineHeight: '30px',
            color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
          },
        },
        {
          props: { variant: 'text-md-b' },
          style: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 'bold',
            color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
          },
        },
        {
          props: { variant: 'text-md' },
          style: {
            fontSize: '16px',
            lineHeight: '24px',
            color: isDarkMode ? theme.palette.text.secondary : theme.palette.text.primary,
          },
        },
        {
          props: { variant: 'text-sm-b' },
          style: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 'bold',
            color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
          },
        },
        {
          props: { variant: 'text-sm' },
          style: {
            fontSize: '14px',
            lineHeight: '20px',
            color: isDarkMode ? theme.palette.text.secondary : theme.palette.text.primary,
          },
        },
        {
          props: { variant: 'text-xs-b' },
          style: {
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 'bold',
            color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
          },
        },
        {
          props: { variant: 'text-xs' },
          style: {
            fontSize: '12px',
            lineHeight: '18px',
            color: isDarkMode ? theme.palette.text.secondary : theme.palette.text.primary,
          },
        },
      ],
    },
  };
}
