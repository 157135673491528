import { AxiosResponse } from 'axios';
import { instance } from './baseApi';
import { config } from '../config/configurations';

export function getPreferences(): Promise<AxiosResponse> {
  return instance.get(`/${config.REACT_APP_API_VERSION}/me/preferences`);
}

export function updatePreferences(payload: any): Promise<AxiosResponse> {
  return instance.put(`/${config.REACT_APP_API_VERSION}/me/preferences`, payload);
}

export function postPreferences(payload: any): Promise<AxiosResponse> {
  return instance.post(`/${config.REACT_APP_API_VERSION}/me/preferences`, payload);
}
