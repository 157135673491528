import { alpha, Theme } from '@mui/material/styles';

export default function Button(theme: Theme) {
  const isDarkMode = theme.palette.mode === 'dark';

  return {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: any }) => ({
          borderRadius: '20px',
          textTransform: 'capitalize',
          ...(ownerState.variant === 'contained' && {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: isDarkMode
                  ? alpha(theme.palette.primary.main, 0.8)
                  : alpha(theme.palette.primary.main, 0.8),
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: isDarkMode
                  ? theme.palette.secondary.dark
                  : theme.palette.secondary.light,
              },
            }),
          ...(ownerState.variant === 'outlined' && {
            color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
            borderColor: theme.palette.secondary.main,
            '&:hover': {
              borderColor: theme.palette.primary.main,
              backgroundColor: isDarkMode
                ? 'rgba(57, 200, 138, 0.1)' // Assuming #39C88A is your primary color
                : 'rgba(57, 200, 138, 0.05)',
            },
          }),
        }),
      },
    },
  };
}
