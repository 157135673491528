import { combineReducers, configureStore } from '@reduxjs/toolkit';
import suggestionsReducer from '@common/pages/suggestions/suggestion.slice';
import themeReducer from '@common/theme/theme.slice';
import settingsReducer from '@common/pages/settings/settings.slice';

const rootReducer = combineReducers({
  suggestions: suggestionsReducer,
  theme: themeReducer,
  settings: settingsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});
