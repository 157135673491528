import React from 'react';
import { styled, LinearProgress } from '@mui/material';

const LoaderWrapper = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 9999,
  width: '100%',
}));

export const Loader = () => {
  return (
    <LoaderWrapper>
      <LinearProgress />
    </LoaderWrapper>
  );
};
