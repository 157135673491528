import { alpha, Theme } from '@mui/material/styles';

export default function Chip(theme: Theme) {
  const isDarkMode = theme.palette.mode === 'dark';

  return {
    MuiChip: {
      variants: [
        {
          props: { variant: 'borderless' },
          style: {
            border: 'none',
            backgroundColor: 'transparent',
            color: isDarkMode ? theme.palette.common.white : theme.palette.text.primary,
            borderRadius: 0,
            fontSize: '12px',
            lineHeight: '18px',
          },
        },
        {
          props: { variant: 'priority-urgent' },
          style: {
            color: isDarkMode ? '#FF6B6B' : theme.palette.text.redNeutral,
            backgroundColor: isDarkMode ? alpha('#FF6B6B', 0.2) : theme.palette.secondary.light,
            textTransform: 'capitalize',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'priority-important' },
          style: {
            color: isDarkMode ? '#FFA500' : theme.palette.text.orangeNeutral,
            backgroundColor: isDarkMode ? alpha('#FFA500', 0.2) : theme.palette.background.orange,
            textTransform: 'capitalize',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'priority-moderate' },
          style: {
            color: isDarkMode ? '#4CAF50' : theme.palette.text.greenNeutral,
            backgroundColor: isDarkMode ? alpha('#4CAF50', 0.2) : theme.palette.background.green,
            textTransform: 'capitalize',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'primary-filled' },
          style: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            textTransform: 'capitalize',
            fontSize: '14px',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, isDarkMode ? 0.9 : 0.8),
            },
          },
        },
        {
          props: { variant: 'priority-low' },
          style: {
            color: isDarkMode ? '#4CAF50' : theme.palette.text.greenNeutral,
            backgroundColor: isDarkMode ? alpha('#4CAF50', 0.2) : theme.palette.background.green,
            textTransform: 'capitalize',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      ],
    },
  };
}
