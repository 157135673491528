import { Theme } from '@mui/material';
import { merge } from 'lodash';
import Typography from './typography';
import Chip from './chip';
import Avatar from './avatar';
import Button from './button';
import Alert from './alert';

export default function componentsOverride(theme: Theme) {
  return merge(Avatar(theme), Button(theme), Chip(theme), Typography(theme), Alert(theme));
}
