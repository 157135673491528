export const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'primary.light',
  },
  subWrapper: {
    margin: 'auto',
    width: 'fit-content',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    mb: 2,
    height: 'max-content',
    width: 500,
    p: 2,
  },
  accountsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressCircle: {
    alignSelf: 'center',
  },
  accountsSubContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
  },
  accountsSubC: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '500px',
    width: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  accountRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mx: 2,
    mb: 2,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      cursor: 'pointer',
    },
  },
  accountImageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 90,
    mr: 2,
  },
  accountTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
  },
};
