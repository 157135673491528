import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, SnackbarOrigin } from '@mui/material';
import { ToastContextType } from './interfaces/ToastContextType';
import { Toast } from './interfaces/Toast';
import Alert, { AlertColor } from '@mui/material/Alert';

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toast, setToast] = useState<Toast | null>(null);

  const showToast = (
    message: string,
    type: AlertColor = 'info',
    duration = 3000,
    position: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' },
  ) => {
    setToast({ message, type, duration, position });
  };

  const handleClose = () => {
    setToast(null);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && (
        <Snackbar
          anchorOrigin={toast.position}
          open={Boolean(toast)}
          autoHideDuration={toast.duration}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={toast.type}>
            {toast.message}
          </Alert>
        </Snackbar>
      )}
    </ToastContext.Provider>
  );
};
