import { useEffect, useState } from 'react';
import { getPermissions } from '../apis/users';
import { IRules } from '@common/interfaces/IAcl';
import { IAddress } from '@common/interfaces/IUser';

export const usePermissions = () => {
  const [rules, setRules] = useState<IRules>(() => {
    const storedRole = sessionStorage.getItem('user_role');
    const storedCountry =
      JSON.parse(localStorage.getItem('user') || 'null')?.addresses?.find(
        (address: IAddress) => address?.primary,
      )?.country ?? null;
    return {
      roles: storedRole ? JSON.parse(storedRole) : ['user'],
      userCountry: storedCountry,
    };
  });

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const storedPermissions = sessionStorage.getItem('user_role');
        if (!storedPermissions) {
          const response = await getPermissions();
          if (response.status === 200) {
            setRules({
              ...rules,
              roles: response.data.permissions,
            });
            sessionStorage.setItem('user_role', JSON.stringify(response.data.permissions));
          } else {
            setRules({
              ...rules,
              roles: ['user'],
            });
            sessionStorage.setItem('user_role', JSON.stringify(['user']));
          }
        }
      } catch (e) {
        setRules({
          ...rules,
          roles: ['user'],
        });
        sessionStorage.setItem('user_role', JSON.stringify(['user']));
      }
    };

    fetchPermissions();
  }, []);

  return { rules };
};
