import React, { Suspense } from 'react';
import { Loader } from './Loader';

const Loadable = (Component: any) => {
  return (props: any) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
