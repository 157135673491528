import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { Authentication } from '../../interfaces/IAuthentication';
import { GlobalVariables, config } from '../../config/configurations';

import axios from 'axios';
import { getCookie } from '../../helpers/cookies';
import { t } from 'i18next';

const easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
const globalVar = GlobalVariables.getInstance();

const LoginForm: React.FC<Authentication> = (_props: Authentication) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [throwAlert, setThrowAlert] = useState(false);
  const [throwAlertMessage, setThrowAlertMessage] = useState('Seems you are not still logged.');

  const from = location.state?.from?.pathname || '/';

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    identifier: Yup.string().required(t('GLOBAL_AUTH_ID_REQUIRED')),
    password: Yup.string().required(t('GLOBAL_AUTH_PASS_REQUIRED')),
  });

  const formik = useFormik({
    initialValues: {
      identifier: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { resetForm }) => {
      axios
        .post(
          `${config.REACT_APP_URL_BACKEND_API}/${config.REACT_APP_API_VERSION}/authentication/oauth2/password/token`,
          {
            grantType: 'password',
            identifier: formik.values.identifier,
            password: formik.values.password,
          },
          { withCredentials: true },
        )
        .then(
          (_response: any) => {
            if (globalVar.getIntegrationMode() === 'addon') {
              localStorage.setItem('session-expiry', getCookie('session-expiry'));
            }
            navigate(from, { replace: true });
          },
          (error: any) => {
            const msg = error?.response ? error.response.data.message : error.message;
            resetForm();
            setThrowAlert(true);
            if (msg) {
              setThrowAlertMessage(msg.charAt(0).toUpperCase() + msg.slice(1).toLowerCase());
            }
            setTimeout(() => {
              setThrowAlert(false);
            }, 10000);
          },
        );
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="identifier"
              label={t('GLOBAL_AUTH_IDADDRESS')}
              {...getFieldProps('identifier')}
              error={Boolean(touched.identifier && errors.identifier)}
              helperText={touched.identifier && errors.identifier}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label={t('GLOBAL_PASSWORD')}
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? (
                        <Icon icon="eva:eye-fill" />
                      ) : (
                        <Icon icon="eva:eye-off-fill" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {throwAlert && <Alert severity="error">{throwAlertMessage}</Alert>}
          <Box component={motion.div} initial={{ opacity: 0, y: 20 }} animate={animate}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              {false && (
                <FormControlLabel
                  control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                  label="Remember me"
                />
              )}
              <Link
                component={RouterLink}
                variant="subtitle2"
                to="/reset-password"
                underline="hover"
                style={{ marginRight: '0px', width: '100%', textAlign: 'end' }}
              >
                {t('GLOBAL_AUTH_FORGETPASS')}?
              </Link>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {isSubmitting ? 'loading...' : 'Login'}
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
