import React, { useEffect } from 'react';

interface MicrosoftLoginProps {
  authorizeUrl: string;
}

const MicrosoftLoginComponent: React.FC<MicrosoftLoginProps> = (props) => {
  const handleLoginClick = () => {
    // Open the authentication window when the login button is clicked
    window.open(props.authorizeUrl, 'MicrosoftAuthWindow', 'width=600,height=400');
  };

  useEffect(() => {
    handleLoginClick();
  }, []);

  return <div>{/* Your component's UI */}</div>;
};

export default MicrosoftLoginComponent;
