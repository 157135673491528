import { IUser } from '../../interfaces/IUser';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { me } from '../../apis/users';
import { forceLogout } from '../../apis/authentication';
import { app } from '@microsoft/teams-js';

// Create the context
export const LoginContext = createContext<any>(null);

export const useAuth = () => useContext(LoginContext);

// Create the provider component
export const LoginProvider: React.FC<any> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<IUser>({} as IUser);

  // Function to handle login
  const login = (user: IUser) => {
    // Perform your login logic here
    setIsLoggedIn(true);
    setUser(user);
  };

  // Function to handle logout
  const logout = () => {
    // Perform your logout logic here
    setIsLoggedIn(false);
    setUser({} as IUser);
    forceLogout();
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    const redirectError = localStorage.getItem('redirectError');

    if (
      redirectError ||
      window.location.pathname === '/login' ||
      window.location.pathname === '/index.html#/login' ||
      window.location.pathname === '/my/accounts'
    ) {
      localStorage.removeItem('redirectError');
      return null;
    }
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    try {
      const user_res = await me();
      if (user_res) {
        if (localStorage.getItem('redirectError')) {
          localStorage.removeItem('redirectError');
        }
        localStorage.setItem('user', JSON.stringify(user_res.data));
        return user_res.data;
      }
      return null;
    } catch (error) {
      if (!redirectError) {
        localStorage.setItem('redirectError', 'true');
      }
      if (app.isInitialized()) {
        window.location.href = '/config';
        return null;
      }
      window.location.href = '/login';
      return null;
    }
  };

  // Value object to be provided by the context
  const value = {
    isLoggedIn,
    user,
    checkLogin,
    login,
    logout,
    setUser,
  };

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};
