import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, CircularProgress, Alert } from '@mui/material';
import { t } from 'i18next';

import { getMultiAccount, selectAccount } from '../../apis/authentication';
import { CapFirst } from '../../components/capitilizer';
import { styles } from './multi-account.style';

const MultiAccount: React.FC = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getAccounts = () => {
    const params = new URLSearchParams(window.location.search);
    const state = params.get('state') || '';
    if (!state) {
      window.location.href = '/login';
      return;
    }

    setLoading(true);
    getMultiAccount(state)
      .then((response) => {
        setAccounts(response.data.accounts);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const selectOrganization = (organizationId: string) => {
    const params = new URLSearchParams(window.location.search);
    const state = params.get('state') || '';
    if (!state) {
      window.location.href = '/login';
      return;
    }

    selectAccount(state, organizationId)
      .then((response: any) => {
        if (response.status === 200) {
          window.location.href = '/';
        }
      })
      .catch((_error: any) => {
        setError(true);
      });
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.subWrapper}>
        <Paper sx={styles.paper}>
          <Box sx={styles.accountsContainer}>
            <img
              src="/assets/images/secondbrain.svg"
              alt="Logo"
              width={40}
              height={40}
              style={{ margin: 10 }}
            />
            <CapFirst
              variant="h6"
              fontWeight="bold"
              color="rgba(0, 0, 0, 0.87)"
              mb={2}
              content={t('GLOBAL_AVAILABLE_ORGANIZATION')}
            />
          </Box>
          {loading && <CircularProgress sx={styles.progressCircle} />}
          {error && <Alert severity="error">{t('GLOBAL_ERROR')}</Alert>}
          <Box sx={styles.accountsSubContainer}>
            <Box sx={styles.accountsSubC}>
              {!error &&
                !loading &&
                accounts.map((account: any) => (
                  <Box
                    key={account.id}
                    onClick={() => selectOrganization(account.id)}
                    sx={styles.accountRow}
                  >
                    <Box sx={styles.accountImageWrapper}>
                      <img src={account.logo} alt="Organization Logo" loading="lazy" />
                    </Box>
                    <Box sx={styles.accountTextWrapper}>
                      <Typography fontWeight="md">{account.name}</Typography>
                      <Typography fontWeight="body-sm">{account.id}</Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default MultiAccount;
