import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { usePermissions } from '../../hooks/usePermissions';
import { IAccessRules } from '../../interfaces/IAcl';

export const PermittedRoute = ({
  children,
  accessRules,
}: {
  children: React.ReactNode;
  accessRules?: IAccessRules;
}) => {
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null);
  const { rules } = usePermissions();
  const location = useLocation();

  const checkPermissions = useCallback(() => {
    if (
      accessRules?.roles.includes('PUBLIC') ||
      rules.roles.some((r: string) => accessRules?.roles.includes(r)) ||
      rules.roles.includes('global_admin')
    ) {
      setIsAllowed(true);
    } else {
      setIsAllowed(false);
    }
  }, [accessRules?.roles, rules.roles]);

  useEffect(() => {
    checkPermissions();
  }, [location.pathname, checkPermissions]);

  if (isAllowed === null) {
    return null;
  }

  if (isAllowed === false) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
