export class GlobalVariables {
  private static instance: GlobalVariables | null = null;
  public integrationMode = 'web';
  public variables: any = {};

  static getInstance(): GlobalVariables {
    if (GlobalVariables.instance === null) {
      GlobalVariables.instance = new GlobalVariables();
    }
    return GlobalVariables.instance;
  }
  public setIntegrationMode(mode: string) {
    const app_configuration = this.getApplicationConfiguration();
    if (app_configuration) {
      app_configuration.integrationMode = mode;
      localStorage.setItem('app_configuration', JSON.stringify(app_configuration));
    } else {
      localStorage.setItem('app_configuration', JSON.stringify({ integrationMode: mode }));
    }
  }

  public set(key: string, value: any) {
    this.variables[key] = value;
  }

  public get(key: string) {
    try {
      return this.variables[key];
    } catch (e) {
      return null;
    }
  }

  public del(key: string) {
    try {
      delete this.variables[key];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  private getApplicationConfiguration() {
    const app_configuration = localStorage.getItem('app_configuration');
    if (app_configuration) {
      const app_configuration_json = JSON.parse(app_configuration);
      return app_configuration_json;
    }
    return null;
  }

  public getIntegrationMode() {
    const app_configuration = this.getApplicationConfiguration();

    if (app_configuration) {
      return app_configuration.integrationMode;
    }
    return null;
  }
}

const getBackendInstance = () => {
  const storedInstance = localStorage.getItem('backend_instance');
  if (!storedInstance) {
    return null;
  }
  const parsedInstance = JSON.parse(storedInstance);
  return parsedInstance.api_base_url;
};
const backendInstance = getBackendInstance();

interface Config {
  REACT_APP_ID: string;
  REACT_APP_CLIENT: string;
  REACT_APP_URL_BACKEND_API: string;
  REACT_APP_API_VERSION: string;
  REACT_APP_URL_BACKEND_API_WS: string;
  REACT_APP_BASE_URL: string;
  COOKIE_SESSION_EXPIRY_NAME: string;
}

export const configTemplate: Config = {
  REACT_APP_ID: process.env.REACT_APP_ID as string,
  REACT_APP_CLIENT: 'sbrain-web',
  REACT_APP_URL_BACKEND_API: backendInstance || process.env.REACT_APP_FALLBACK_URL,
  REACT_APP_API_VERSION: 'v1',
  REACT_APP_URL_BACKEND_API_WS: process.env.REACT_APP_WSS_URL as string,
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL as string,
  COOKIE_SESSION_EXPIRY_NAME: 'session-expiry',
};

export const config: Config = configTemplate;
